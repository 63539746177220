import { Box, Text, Card, Loader, MarketingPageLayout, MarketingPageLayoutContent, Page, WixDesignSystemProvider, Image, Layout, FeatureList, Button } from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import Hero from './hero.png';
import { ReactTagManager } from 'react-gtm-ts';
import React, { useEffect } from 'react';
import * as Icons from '@wix/wix-ui-icons-common';

const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}


function App() {
  ReactTagManager.init(tagManagerArgs)
  const token = new URLSearchParams(window.location.search).get('token');
  const instance = new URLSearchParams(window.location.search).get('instance')
  const [isUpgraded, setIsUpgraded] = React.useState(false);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: ""
    }
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
  }, [])


  const BASE_URL = `https://certifiedcode.wixsite.com/magic-link/_functions`

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash
          });
        }
      })
      .catch(() => {

      })
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {

      })
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=59a9cb8c-bfb0-44e5-8242-062aef61fe11&redirectUrl=https://certifiedcode.wixsite.com/magic-link/_functions/@certifiedcode/base-backend/auth`;
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Content>
          <Box height={"90vh"} direction="vertical" verticalAlign="middle">
            <Loader text="Loading" />
          </Box>
        </Page.Content>
      </Page></WixDesignSystemProvider>
  }

  return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
    <Card>
      <MarketingPageLayout
        removeImageHorizontalPadding
        removeImageVerticalPadding
        content={
          <Box height="840px" verticalAlign="middle">
            <MarketingPageLayoutContent
              title="Let users sign in password-less"
              content="Magic Link is a secure, password-less authentication method that allows users to sign in with a single click. It’s a great way to increase conversion rates and reduce support costs."
              actions={<Box direction="horizontal" gap="SP1">
                {/* <Button skin="premium" disabled={isUpgraded} prefixIcon={<Icons.PremiumFilled />}
                  onClick={() => {
                    window.open(`https://www.wix.com/apps/upgrade/${"b9ffe9e9-c23e-4a5c-b352-655d4f7b3e47"}?appInstanceId=${(instanceData as any)['instance']['instanceId']}`)
                  }}
                >Upgrade App</Button> */}
                <Button skin='inverted' as='a' href='https://bridget.reviews.certifiedcode.us/59a9cb8c-bfb0-44e5-8242-062aef61fe11' target='_blank'>Rate Magic Link 5 stars</Button></Box>}
            />
          </Box>
        }
        image={<Image borderRadius={0} src={Hero} />}
      />
    </Card>
  </WixDesignSystemProvider >
}

export default App;
